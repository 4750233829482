<template>
    <div class="info-card-panner">
        <header class="panner-hd">
            <el-link
                @click="isEdit=true"
                v-if="!isEdit"
                type="primary"
                class="pull-right"
                icon="jk-icon-tianjia"
            >添加业绩</el-link>
            <h2 class="tit">
                <span class="line">业绩</span>
            </h2>
        </header>
        <div class="panner-bd user-introduce user_introduce" v-if="isEdit">
            <el-form
                ref="form"
                :model="formData"
                :rules="rules"
                label-width="105px"
                label-position="left"
            >
                <div class="add-form">
                    <el-col :span="12">
                        <el-form-item label="项目名称" prop="title">
                            <el-input v-model="formData.title" clearable placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="- 项目时间" prop="starttime">
                            <!-- <el-date-picker
                                v-model="formData.starttime"
                                type="date"
                                placeholder="选择项目开始时间"
                            ></el-date-picker>&nbsp;&nbsp;至&nbsp;&nbsp;
                            <el-date-picker
                                v-model="formData.endtime"
                                type="date"
                                placeholder="选择项目开始时间"
                            ></el-date-picker>-->

                            <el-date-picker
                                class="width-100 task_time_box"
                                v-model="formData.work_time"
                                :picker-options="datePicker"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="选择项目开始时间"
                                end-placeholder="选择项目开始时间"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="负责人" prop="leader">
                            <el-input v-model="formData.leader" clearable placeholder="请输入负责人姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="委托单位" prop="company">
                            <el-input v-model="formData.company" clearable placeholder="请填写委托单位名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="项目描述" prop="description">
                            <el-input
                                class="introduce-textarea"
                                type="textarea"
                                placeholder="请填写项目描述"
                                v-model="formData.description"
                                maxlength="2000"
                                show-word-limit
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="项目职责" prop="responsibility">
                            <el-input
                                class="introduce-textarea"
                                type="textarea"
                                placeholder="请填写您在这个项目中的项目职责"
                                v-model="formData.responsibility"
                                maxlength="1000"
                                show-word-limit
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <div class="cont-btn sure-add">
                            <el-button @click="save" type="primary">保存</el-button>
                            <el-button @click="isEdit=false">取消</el-button>
                        </div>
                    </el-col>
                </div>
            </el-form>
        </div>
        <div class="achievement-items-wraper">
            <article class="item" v-for="(item,index) in achievements" :key="index">
                <el-row>
                    <el-col :span="24">
                        <span>{{item.title}}</span>
                        <span class="grey time">{{item.starttime}}-{{item.endtime}}</span>
                        <el-link
                            icon="jk-icon-lajitong el-icon-m"
                            :underline="false"
                            @click="del_personal(item.id)"
                        ></el-link>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">
                        <span class="tit light-black">负责人:</span>
                    </el-col>
                    <el-col :span="21">
                        <div class="right-cont">
                            <span class="name" v-for="(u,i) in item.leader" :key="i">{{u}}</span>
                            <span class="company-name grey">{{item.company}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">
                        <span class="tit light-black">项目描述:</span>
                    </el-col>
                    <el-col :span="21">
                        <div class="right-cont">{{item.description}}</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">
                        <span class="tit light-black">项目职责:</span>
                    </el-col>
                    <el-col :span="21">
                        <div class="right-cont">{{item.responsibility}}</div>
                    </el-col>
                </el-row>
            </article>
        </div>
    </div>
</template>
<script>
import { formatdata } from "@/libs/filter.js";
export default {
    props: ['value'],
    data() {
        return {
            datePicker: this.processDate(),
            isEdit: false,
            formData: {
                id: "",
                geek_type: 1,
                title: "",
                work_time: "",
                leader: "",
                others: "",
                company: "",
                description: "",
                responsibility: ""
            },
            achievements:[],
            rules: {
                title: {
                    required: true,
                    message: "请输入项目名称",
                    trigger: ["change", "blur"]
                },
                work_time: {
                    required: true,
                    message: "请选择开始时间和结束时间",
                    trigger: ["change", "blur"]
                },
                leader: {
                    required: true,
                    message: "请输入负责人",
                    trigger: ["change", "blur"]
                },
                company: {
                    required: true,
                    message: "请填写委托单位",
                    trigger: ["change", "blur"]
                },
                description: {
                    required: true,
                    message: "请填写项目描述",
                    trigger: ["change", "blur"]
                },
                responsibility: {
                    required: true,
                    message: "请填写项目职责",
                    trigger: ["change", "blur"]
                }
            },
            // pickerOptions: {
            //     disabledDate(time) {
            //         return time.getTime() < Date.now();
            //     }
            // }
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        upload_personal(item = ''){
            let _this = this;
            _this.formData.id = '';
            _this.formData.geek_type = 1;
            _this.formData.title = '';
            _this.formData.work_time = [];
            _this.formData.leader = '';
            _this.formData.others = '';
            _this.formData.company = '';
            _this.formData.description = '';
            _this.formData.responsibility = '';
            if (item){
                _this.formData.id = item.id;
                _this.formData.geek_type = 1;
                _this.formData.title = item.title;
                _this.formData.work_time[0] = item.starttime;
                _this.formData.work_time[1] = item.endtime;
                _this.formData.leader = item.leader;
                _this.formData.others = '';
                _this.formData.company = item.company;
                _this.formData.description = item.description;
                _this.formData.responsibility = item.responsibility;
            }
            _this.isEdit = true;
        },
        processDate() {
            return {
                disabledDate(time) {
                    if(time.getTime() > Date.now()){
                        return true;
                    }
                }
            };
        },
        //业绩管理新增
        save() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    let _formData = this.formData;
                    _formData.starttime = _formData.work_time[0];
                    _formData.endtime = _formData.work_time[1];
                    //新增业绩
                    this.post("/user/geek/add_achievement", _formData, data => {
                        if (data.code == 200) {
                            this.formData = {}; //输入新数据清空表单
                            this.isEdit = false; //关闭表单填写

                            this.initData();
                        } else {
                            this.$message.error(data.msg);
                            return;
                        }
                    });
                }
            });
        },
        del_personal(id){
            this.post("/user/geek/del_achievement", {id:id}, data => {
                if (data.code == 200) {
                    this.initData();
                } else {
                    this.$message.error(data.msg);
                    return;
                }
            });     
        },
        initData(){
            this.post("/user/geek/achievements", {}, data => {
                if (data.code == 200) {
                    this.achievements = data.data; //输入新数据清空表单
                    this.isEdit = false; //关闭表单填写

                    this.$emit("input", this.achievements); //将新的业绩信息传往父组件
                } else {
                    this.$message.error(data.msg);
                    return;
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.user_introduce {
    .el-col {
        margin-bottom: 8px;
    }
}
</style>