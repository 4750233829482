<template>
    <!-- 填写基本信息  -->
    <div class="attestation">
        <div class="white-bg">
            <section class="secton attestation-sec">
                <div class="form-panner base-info-panner">
                    <header class="panner-hd">
                        <h3 class="main-tit">{{attestation_type==1?'个人雇主认证':'单位雇主认证'}}</h3>
                    </header>
                    <edit-person
                        :attestation="attestation"
                        v-if="attestation_type==1"
                        @onSave="onSave"
                    />
                    <edit-unit
                        :attestation="attestation"
                        v-if="attestation_type==2"
                        @onSave="onSave"
                    />
                    <div class="panner-footer"></div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import editPerson from "./module/person.vue";
import editUnit from "./module/unit.vue";
export default {
    name: "ucIndex",
    components: {
        editPerson, // 用户信息
        editUnit // 去认证工程极客
    },
    data() {
        return {
            attestation_type: 0,
            attestation: []
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo", "setAttestation"]),
        onSave(data) {//刷新用户数据
            this.ajaxUserInfo();
            this.setAttestation(data);
        }
    },
    created() {
        setTimeout(() => {
            //认证状态（0.未认证；1.待审核；2.已认证；3.审核失败；）
            if (this.userInfo.info.attestation == 1 || this.userInfo.info.attestation == 2) {
                this.$router.push("/user/index");
            }

            if (this.userInfo.info.attestation == 3) {//编辑认证信息
                this.attestation_type = this.userInfo.info.attestation_type;
                this.attestation = this.userInfo.attestation;
            }

            if (this.userInfo.info.attestation == 0) {//添加认证信息

                let type = this.$route.query.type;
                if (type) {
                    this.attestation_type = type;
                } else {
                    this.$router.push("/user/index");
                }
            }
        }, 1000);
    },
};
</script>

<style lang="less" scoped>
@import "../../../../styles/ucattestation.less";

.line-horizontal {
    margin: 20px 40px;
}
.check {
    margin: 10px 40px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 0px;
    color: #8c939d;
    width: 200px;
    height: 126px;
    line-height: 178px;
    text-align: center;
    background-image: url("~@/assets/img/idcard_up_back.png");
}
.avatar {
    width: 200px;
    height: 126px;
    display: block;
}
.center {
    margin-top: 40px;
    text-align: center;
}
.front {
    .avatar-uploader-icon {
        background-image: url("~@/assets/img/idcard_up_front.png");
    }
}
</style>