<template>
    <div
        class="form-panner-bd bi-bd"
        v-loading="loading"
    >
        <div
            class="form-cont"
            :class="{'border-none':activeStepNum==2 }"
        >
            <el-form
                ref="form"
                :model="formData"
                :rules="rules"
                label-width="128px"
                label-position="left"
            >
                <el-row :gutter="20">
                    <div
                        class="form-basisinfo steps steps_box"
                        v-if="activeStepNum==1"
                    >
                        <!--  第一步 填写基本信息  -->
                        <el-col :span="24">
                            <el-form-item
                                label="姓名"
                                prop="name"
                            >
                                <el-input
                                    v-model="formData.name"
                                    clearable
                                    placeholder="请输入姓名"
                                    @blur="local_save"
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item
                                label="身份证号码"
                                prop="idcard"
                            >
                                <el-input
                                    v-model="formData.idcard"
                                    clearable
                                    placeholder="请输入身份证号码"
                                    @blur="local_save"
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item
                                label="上传身份证照片"
                                prop="idcard_picture"
                            ></el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-col
                                :span="6"
                                :push="3"
                            >
                                <only-upload
                                    @onupload="upload_pictureUP"
                                    :before-upload="before_upload"
                                >
                                    <div slot="trigger">
                                        <img
                                            v-if="pictureUP"
                                            :src="util.reImg(pictureUP)"
                                            class="avatar"
                                        />
                                        <i
                                            v-else
                                            class="el-icon-plus avatar-uploader-icon avatar-uploader-icon-front"
                                        ></i>
                                    </div>
                                </only-upload>
                            </el-col>

                            <el-col
                                :span="6"
                                :push="3"
                            >
                                <only-upload
                                    @onupload="upload_pictureDown"
                                    :before-upload="before_upload"
                                >
                                    <div slot="trigger">
                                        <img
                                            v-if="pictureDown"
                                            :src="util.reImg(pictureDown)"
                                            class="avatar"
                                        />
                                        <i
                                            v-else
                                            class="el-icon-plus avatar-uploader-icon"
                                        ></i>
                                    </div>
                                </only-upload>
                            </el-col>
                        </el-col>

                        <el-col
                            :span="14"
                            :push="3"
                            class="idcard_tips"
                        >
                            <span>图片小于5M文件，格式为JPG/jpeg/png/bmp</span>
                        </el-col>

                        <el-col :span="24">
                            <div class="line-horizontal"></div>

                            <el-checkbox
                                v-model="isReadRead"
                                class="check"
                                style="margin:10px 0 10px 40px"
                            >我已阅读并同意</el-checkbox>

                            <span
                                style="cursor:pointer;color:#409EFF;"
                                @click="showArticleDetail"
                            >《询龙网平台服务协议》</span>

                            <div class="cont-btn center">
                                <el-button
                                    type="primary"
                                    :disabled="!isReadRead"
                                    @click="save"
                                >提交认证</el-button>
                            </div>
                        </el-col>
                    </div>

                    <div
                        class="info-inner"
                        v-if="activeStepNum==2"
                    >
                        <div class="info-card-panner border-0">
                            <div class="panner-bd">
                                <div class="tips-inner">
                                    <span class="icon-wrap">
                                        <i class="jk-icon-gou icon"></i>
                                    </span>

                                    <p class="tips">提交成功</p>

                                    <p class="desc">
                                        您所填写的资料已经顺利提交，审核结果将在
                                        <span
                                            class="yellow"
                                            v-text="passDate"
                                        ></span>以短信方式通知您，请耐心等待。
                                    </p>

                                    <router-link to="/user/index">
                                        <el-button>返回个人中心</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-row>
            </el-form>
        </div>

        <el-dialog
            title="询龙网平台服务协议"
            :visible.sync="dialogAgreement"
        >
            <div v-html="articleContent"></div>
        </el-dialog>

    </div>
</template>

<script>
import onlyUpload from "@/components/upload/only-upload.vue";

export default {
    components: {
        onlyUpload
    },

    props: ['attestation'],

    data() {
        return {
            dialogAgreement: false,//用户协议弹出框
            articleContent: '',
            activeStepNum: 1,
            isReadRead: false,
            pictureUP: "",
            pictureDown: "",
            passDate: "",
            loading: false,
            formData: {
                employer_type: 1,
                organ_type: 0,
                name: "",
                idcard: "",
                idcard_picture: "",
                organizational_code: "",
                organ_file: "",
                contacts_name: "",
                contacts_phone: "",
                contacts_email: ""
            },

            rules: {
                name: {
                    required: true,
                    message: "请输入姓名",
                    trigger: ["change", "blur"]
                },

                idcard: [
                    {
                        required: true,
                        message: "请输入身份证",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: this.validata.idCard(),
                        trigger: ["change", "blur"]
                    }
                ],

                // idcard_picture: {
                //     required: true,
                //     message: "请上传身份证照片",
                //     trigger: ["change", "blur"]
                // },

                birthday: [{
                    required: true,
                    message: "选择出生年月",
                    trigger: ["change", "blur"]
                },
                {
                    validator: function (rule, value, callback) {
                        if (value) {
                            if (value.toString().indexOf("中国") != -1) {
                                let _age = parseInt((new Date().getTime() / 86400000) - (value.getTime() / 86400000 + new Date().getDate()));//当前天数-选择天数=已经经过了多少天数
                                _age = _age / 30 / 12;//算成年
                                if (_age >= 18 && _age <= 99) {
                                    callback();
                                } else {
                                    callback(new Error('年龄必须大于18岁及以上'));
                                }
                            } else {
                                callback();
                            }
                        } else {
                            callback();
                        }
                    },
                    trigger: ["change", "blur"]
                }
                ]
            }
        };
    },

    methods: {
        upload_pictureUP(data) {
            this.pictureUP = data.file;
            this.formData.idcard_picture = this.pictureUP + "," + this.pictureDown;
            this.local_save();
        },

        upload_pictureDown(data) {
            this.pictureDown = data.file;
            this.formData.idcard_picture = this.pictureUP + "," + this.pictureDown;
            this.local_save();
        },

        save() {
            let _this = this;
            if (_this.pictureUP && _this.pictureDown) {
                _this.formData.idcard_picture = _this.pictureUP + "," + _this.pictureDown;
            }
            _this.$refs["form"].validate(valid => {
                if (valid) {
                    _this.loading = true;
                    _this.post(
                        "/user/employer/attestation",
                        _this.formData,
                        data => {
                            _this.loading = false;
                            if (200 == data.code) {
                                sessionStorage.removeItem('employer_atte_form_data');
                                _this.activeStepNum = 2;
                                _this.passDate = data.data.review_time;
                                _this.$emit("onSave", data.data);
                            } else {
                                _this.$message.error(data.msg);
                                return;
                            }
                        }
                    );
                }
            });
        },

        //获取文章详情
        goArticleDetail() {
            var _this = this;
            _this.post(
                "/article/index/detail",
                { id: 2 },
                function (res) {
                    if (res.code == 200) {
                        _this.articleContent = res.data.content;
                    }
                }
            );
        },

        //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },

        //弹出雇主认证条例
        showArticleDetail() {
            this.dialogAgreement = true;
        },

        //缓存表单
        local_save() {
            sessionStorage.setItem('employer_atte_form_data', JSON.stringify(this.formData));
        }
    },

    mounted() {
        let _data = sessionStorage.getItem('employer_atte_form_data');
        if (_data) {
            this.formData = JSON.parse(_data);
            let idcard_array = this.formData.idcard_picture.split(',');
            // console.log(idcard_array[0]);
            this.pictureUP = idcard_array[0];
            this.pictureDown = idcard_array[1];
        } else {
            if (this.attestation.name) {
                let _data = this.attestation;
                this.pictureUP = _data.idcard_picture[0];
                this.pictureDown = _data.idcard_picture[1];
                this.formData = this.attestation;
            }
        }
    },

    created() {
        this.goArticleDetail();//获取文章详情
    }
};
</script>

<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.line-horizontal {
    margin: 20px 40px;
}
.check {
    margin: 10px 40px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 0px;
    color: #8c939d;
    width: 200px;
    height: 126px;
    line-height: 178px;
    text-align: center;
    background-image: url("~@/assets/img/idcard_up_back.png");
}
.avatar {
    width: 200px;
    height: 126px;
    display: block;
}
.center {
    margin-top: 40px;
    text-align: center;
}
.avatar-uploader-icon-front {
    background-image: url("~@/assets/img/idcard_up_front.png");
}
.steps_box {
    .el-col {
        margin-bottom: 10px;
    }
}
.idcard_tips {
    line-height: 36px;
    margin-left: 20px;
    color: #b1b1b3;
    font-size: 12px;
}
</style>