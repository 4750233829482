<template>
    <el-upload
        class="upload-el"
        ref="upload"
        :action="action"
        :headers="imgHeaders"
        :multiple="false"
        :on-progress="on_progress"
        :on-success="on_success"
        :on-error="on_error"
        :before-upload="before_upload"
    >
        <div slot="trigger">
            <slot name="trigger"></slot>
        </div>
        <slot></slot>
    </el-upload>
</template>
<script>
export default {
    name: "imgUpload",
    data() {
        return {
            action: ""
        };
    },
    computed: {
        imgHeaders: function () {
            //图片上传头部信息
            return {
                token: sessionStorage.getItem("token"),
                timestamp: new Date().getTime(),
                secret: this.websiteConfigs.secret
            };
        }
    },
    created() {
        var token = sessionStorage.getItem("token");
        this.action =
            this.websiteConfigs.baseUrl +
            this.websiteConfigs.uploadUrl +
            "?group=user";
    },
    methods: {
        on_progress(event, file, fileList) { },

        on_success(response, file, fileList) {
            this.$emit("onupload", response.data);
        },

        on_error(err, file, fileList) { },

        //上传前
        before_upload(file) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if (
                    "jpg" == suffix ||
                    "jpeg" == suffix ||
                    "png" == suffix ||
                    "gif" == suffix
                ) { } else {
                    this.$message.error(
                        "上传图片格式必须是jpg、jpeg、gif或者png"
                    );
                    return false;
                }
            }
            this.imgHeaders.timestamp = new Date().getTime();
        }
    }
};
</script>