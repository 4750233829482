<template>
    <div class="info-card-panner">
        <header class="panner-hd">
            <el-link
                @click="add_personal"
                v-if="!isEdit"
                type="primary"
                class="pull-right"
                icon="jk-icon-tianjia"
            >添加人员</el-link>
            <h2 class="tit">
                <span class="line">添加</span>人员信息（选填）
            </h2>
        </header>

        <div class="panner-bd user-introduce user_introduce_box" v-if="isEdit">
            <div class="add-form add_form">
                <el-form
                    ref="formPersonal"
                    :model="formData"
                    :rules="rules"
                    label-width="125px"
                    label-position="left"
                >
                    <el-col :span="24">
                        <el-form-item label="头像" prop="avatar" class="avatar_box">
                            <only-upload @onupload="upload_avatar" :before-upload="before_upload">
                                <div slot="trigger">
                                    <img
                                        v-if="formData.avatar"
                                        :src="util.reImg(formData.avatar)"
                                        class="avatar"
                                    />
                                    <i v-else class="jk-icon-tianjia avatar-uploader-icon" :style="{backgroundImage:'url('+$img.idcard_up_back+')'}"></i>
                                </div>
                            </only-upload>
                        </el-form-item>
                        <el-form-item class="avatar_tips">
                            <span class="yellow">大小:</span>
                            <span class="grey">200像素 * 200像素</span>&nbsp;&nbsp;
                            <span class="yellow">格式:</span>
                            <span class="grey">支持png、jpg、jpeg、gif格式</span>
                        </el-form-item>
                    </el-col>

                    <el-col :span="10">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="formData.name" clearable placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" :offset="2">
                        <el-form-item label="出生年月" prop="birthday">
                            <el-date-picker
                                v-model="formData.birthday"
                                type="month"
                                placeholder="选择出生年月"
                                :picker-options="datePicker"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="10">
                        <el-form-item label="性别" prop="sex">
                            <el-select v-model="formData.sex" placeholder="选择性别">
                                <el-option
                                    v-for="(item,index) in sexs"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" :offset="2">
                        <el-form-item label="从事专业" prop="profession">
                            <el-input v-model="formData.profession" clearable placeholder="请输入从事专业"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="10">
                        <el-form-item label="职称" prop="professional_qualifications">
                            <el-input
                                v-model="formData.professional_qualifications"
                                clearable
                                placeholder="请输入从事专业"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" :offset="2">
                        <el-form-item label="学历" prop="education">
                            <el-input v-model="formData.education" clearable placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="参加工作年月" prop="experience_date" class="pull-left">
                            <el-date-picker
                                :picker-options="datePicker"
                                v-model="formData.experience_date"
                                type="month"
                                placeholder="选择参加工作年月"
                                :disabled="experience_date_disabled"
                            ></el-date-picker>
                        </el-form-item>

                        <el-form-item label class="pull-left no-workMonth">
                            <el-checkbox v-model="experience" @change="change">暂无工作经历</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-form>
                <div class="zs">
                    <p class="add-top-tit grey add_top_title" style="margin-bottom: 10px;">证书列表</p>
                    <div class="up-certificate-list" v-if="formData.certificate">
                        <el-row v-for="(item,index) in formData.certificate" :key="index">
                            <el-col :span="8">{{item.title}}</el-col>
                            <el-col :span="8" class="grey">证书编号：{{item.code}}</el-col>
                            <el-col :span="3">
                                <el-link
                                    :underline="false"
                                    type="primary"
                                    @click="showImg(item.image)"
                                >查看</el-link>&nbsp;&nbsp;
                                <el-link :underline="false" @click="del_Certificate(index)">删除</el-link>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="add-cer-btn-wrap">
                    <el-link
                        type="primary"
                        :underline="false"
                        icon="jk-icon-tianjia"
                        v-show="!isCertificate"
                        @click="add_certificate"
                    >添加证书</el-link>
                </div>

                <el-form
                    ref="formCertificate"
                    :model="certificateForm"
                    :rules="certificateRules"
                    label-width="125px"
                    label-position="left"
                    v-show="isCertificate"
                    class="formCertificate"
                >
                    <div class="line-orientation mb-20"></div>

                    <el-col :span="24">
                        <el-form-item label="证书名称" prop="title">
                            <el-input
                                class="width-100"
                                v-model="certificateForm.title"
                                clearable
                                placeholder="请输入证书名称"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="证书编号" prop="code">
                            <el-input
                                v-model="certificateForm.code"
                                clearable
                                placeholder="请输入证书编号"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="获得时间" prop="time">
                            <el-date-picker
                                v-model="certificateForm.time"
                                type="month"
                                :picker-options="datePicker"
                                placeholder="选择获得证书的时间"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="上传证件" prop="image">
                            <el-input
                                v-if="certificateForm.image"
                                class="up-disable-ipt"
                                v-model="certificateForm.image"
                                clearable
                                :disabled="true"
                                placeholder="请上传证件照片"
                            ></el-input>

                            <only-upload @onupload="upload_certificate">
                                <el-link
                                    icon="jk-icon-shangchuan"
                                    slot="trigger"
                                    type="primary"
                                    :underline="false"
                                >{{ certificateForm.image?'重新上传':'上传图片' }}</el-link>

                                <div slot="tip" class="el-upload__tip">小于5M</div>
                            </only-upload>

                            <div class="cont-btn add-cer pull-right">
                                <el-button
                                    @click="save_Certificate"
                                    type="primary"
                                >{{certificateForm.index>-1?'确认保存':'确认添加'}}</el-button>
                                <el-button @click="isCertificate=false;">取消</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-form>

                <div class="line-orientation mt-10 mb-30"></div>

                <el-col :span="24">
                    <div class="cont-btn sure-add">
                        <el-button @click="save_personal" type="primary">保存</el-button>
                        <el-button @click="isEdit=false;achievement_wraper=true;">取消</el-button>
                    </div>
                </el-col>
            </div>
        </div>

        <div class="achievement-items-wraper" v-if="achievement_wraper">
            <article class="media-box" v-for="(item,index) in list" :key="index">
                <el-row>
                    
                    <el-col :span="3">
                        <div class="avatarBox">
                            <img v-if="item.avatar" class="avator" :src="util.reImg(item.avatar)" />
                        </div>
                    </el-col>

                    <el-col :span="21">
                        <el-row class="mt-5">
                            <el-col :span="22">
                                {{item.name}}
                                <i
                                    class="color-main ml-10"
                                    :class="[item.sex==1?'jk-icon-male':'jk-icon-female']"
                                ></i>
                            </el-col>

                            <el-col :span="2" class="edit-col">
                                <el-link
                                    icon="jk-icon-bianjilanse el-icon-m"
                                    :underline="false"
                                    @click="upload_personal(item.id)"
                                ></el-link>

                                <el-link
                                    icon="jk-icon-lajitong el-icon-m"
                                    :underline="false"
                                    @click="del_personal(item.id)"
                                ></el-link>
                            </el-col>
                        </el-row>

                        <el-row class="mt-10">
                            <span class="grey mr-5">年龄:</span>
                            {{item.age}}岁
                            <span
                                class="grey ml-10 mr-5"
                            >学历:</span>
                            {{item.education}}
                            <span
                                class="grey ml-10 mr-5"
                            >专业:</span>
                            {{item.profession}}
                            <span
                                class="grey ml-10 mr-5"
                            >职称:</span>
                            {{item.professional_qualifications}}
                        </el-row>

                        <el-row class="mt-10" v-if="item.certificate">
                            <el-col :span="2">
                                <span class="grey mr-5">证书:</span>
                            </el-col>
                            <el-col :span="16">
                                <div
                                    class="cer-item mb-10"
                                    v-for="(certifi,ci) in item.certificate"
                                    :key="ci"
                                >
                                    <el-col :span="9">{{certifi.title}}</el-col>
                                    <el-col :span="13" class="grey">证书编号：{{certifi.code}}</el-col>
                                    <el-col :span="2">
                                        <el-link type="primary" @click="showImg(certifi.image)">查看</el-link>
                                    </el-col>
                                </div>
                            </el-col>
                        </el-row>

                        <div class="line-orientation"></div>
                    </el-col>
                </el-row>
            </article>
        </div>

        <view-image v-model="isShow" :images-list="imgList" />
    </div>
</template>

<script>
import onlyUpload from "@/components/upload/only-upload.vue";
import { formatdata } from "@/libs/filter.js";
export default {
    name: "addPersonal",

    props: ['value'],

    components: { onlyUpload },

    data() {
        return {
            achievement_wraper: true, //人员列表
            isEdit: false,
            isCertificate: false,
            isShow: false,
            imgList: [],
            list: [],
            sexs: [
                {
                    value: 1,
                    label: "男"
                },
                {
                    value: 2,
                    label: "女"
                }
            ],
            experience: "",
            experience_date_disabled: false,
            datePicker: this.processDate(),
            formData: {
                id: "",
                uk: "",
                name: "",
                sex: "",
                birthday: "",
                avatar: "",
                education: "",
                profession: "",
                professional_qualifications: "",
                experience_date: "",
                certificate: []
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入姓名",
                    trigger: ["change", "blur"]
                },
                sex: {
                    required: true,
                    message: "请选择性别",
                    trigger: ["change", "blur"]
                },
                birthday: [
                    {
                        required: true,
                        message: "请选择出生年月",
                        trigger: ["change", "blur"]
                    },
                ],
                avatar: {
                    required: true,
                    message: "请上传头像",
                    trigger: ["change", "blur"]
                },
                education: {
                    required: true,
                    message: "请填写学历",
                    trigger: ["change", "blur"]
                },
                profession: {
                    required: true,
                    message: "请填写专业",
                    trigger: ["change", "blur"]
                },
                professional_qualifications: {
                    required: true,
                    message: "请填写职称",
                    trigger: ["change", "blur"]
                },
                experience_date: {
                    required: true,
                    message: "选择参加工作日期",
                    trigger: ["change", "blur"]
                }
            },
            certificateForm: {
                title: "",
                code: "",
                time: "",
                image: ""
            },
            certificateRules: {
                title: {
                    required: true,
                    message: "请输入证书名称",
                    trigger: ["change", "blur"]
                },
                code: {
                    required: true,
                    message: "请输入证书编号",
                    trigger: ["change", "blur"]
                },
                time: {
                    required: true,
                    message: "请选择获得时间",
                    trigger: ["change", "blur"]
                },
                image: {
                    required: true,
                    message: "请上传证件",
                    trigger: ["change", "blur"]
                }
            }
        };
    },

    methods: {
        add_certificate() {
            this.certificateForm = {
                title: "",
                code: "",
                time: "",
                image: ""
            };
            this.isCertificate = true;
        },
        upload_certificate(data) {
            this.certificateForm.image = data.file;
        },
        processDate() {
            return {
                disabledDate(time) {
                    if(time.getTime() > Date.now()){
                        return true;
                    }
                }
            };
        },
        save_Certificate() {
            let _this = this;
            _this.$refs["formCertificate"].validate(valid => {
                if (valid) {
                    _this.isCertificate = false;
                    let _object = _this.certificateForm;
                    _object.time = formatdata(
                        _object.time.getTime(),
                        "yyyy-MM"
                    );

                    if (!_this.formData.certificate) {
                        _this.formData.certificate = [];
                    }

                    if (_object.index > -1) {
                        let _edit = _object;
                        _this.$set(_this.formData.certificate, _object.index, {
                            title: _object.title,
                            code: _object.code,
                            time: _object.time,
                            image: _object.image
                        });
                    } else {
                        _this.formData.certificate.push(_object);
                    }
                }
            });
        },
        del_Certificate(i) {
            this.formData.certificate = this.formData.certificate.filter(
                (c, index) => index != i
            );
        },
        edit_Certificate(i) {
            let _edit = JSON.parse(
                JSON.stringify(this.formData.certificate[i])
            );
            if (_edit.time) {
                _edit.time = new Date(_edit.time);
            }
            _edit["index"] = i;
            this.certificateForm = _edit;
            this.isCertificate = true;
        },

         //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },
        
        add_personal() {
            this.formData = {
                name: "",
                sex: "",
                birthday: "",
                avatar: "",
                education: "",
                profession: "",
                professional_qualifications: "",
                experience: 1,
                experience_date: "",
                certificate: []
            };
            this.isEdit = true;
            this.achievement_wraper = false;
        },

        //暂无工作经历checkbox
        change() {
            if (this.experience == true) {
                this.formData.experience_date = ""; //清空参加工作年月
                this.experience_date_disabled = true; //参加工作年月不可用状态
                this.rules.experience_date.required = false; //关闭参加工作年月必填
            } else {
                this.formData.experience_date = new Date();
                this.experience_date_disabled = false; //参加工作年月可用状态
                this.rules.experience_date.required = true; //打开参加工作年月必填
            }
        },

        //更新头像
        upload_avatar(data) {
            this.formData.avatar = data.file;
        },

        //新增人员信息
        save_personal() {
            let _this = this;
            _this.$refs["formPersonal"].validate(valid => {
                if (valid) {
                    let _formData = this.formData;

                    _formData.birthday = formatdata(
                        new Date(_formData.birthday).getTime(),
                        "yyyy-MM"
                    );

                    if (_formData.experience_date) {
                        _formData.experience_date = formatdata(
                            new Date(_formData.experience_date).getTime(),
                            "yyyy-MM"
                        );
                    }

                    let _url = "/user/geek/add_personal";
                    //判断是否有工作经历
                    if (_this.experience == true) {
                        _formData.experience = 1;
                    } else {
                        _formData.experience = 0;
                    }

                    //新增业绩
                    _this.post(_url, _formData, data => {
                        if (data.code == 200) {
                            _this.formData = {}; //输入新数据清空表单
                            _this.isEdit = false; //关闭表单填写
                            _this.achievement_wraper = true; //显示人员列表

                            //把不是本次修改的对象加到新数组
                            let personal_array = [];
                            _this.list.forEach(function (item) {
                                if (item.id != _formData.id) {
                                    personal_array.push(item);
                                }
                            });

                            //把修改后的对象新数据加入数组
                            personal_array.push(data.data);
                            _this.list = personal_array;

                            _this.$emit("input", _this.list); //将新的人员信息传往父组件
                            _this.$message.success(data.msg);
                        } else {
                            _this.$message.error(data.msg);
                            return;
                        }
                    });
                }
            });
        },

        upload_personal(id) {
            let _data = this.list.find(c => c.id == id);
            _data.birthday = new Date(_data.birthday);
            _data.experience_date = new Date(_data.experience_date);
            this.formData = _data;
            this.isEdit = true;
            this.achievement_wraper = false;
        },

        del_personal(id) {
            this.list = this.list.filter(c => c.id != id);
        },

        showImg(img) {
            this.imgList = [];
            this.imgList.push(this.util.reImg(img));
            this.isShow = true;
        }
    },

    mounted() {
        this.list = this.value;//给表单赋值父组件传进来的值
    }
};
</script>

<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.panner-bd {
    overflow: hidden;
}
.step3-info .panner-bd {
    height: auto !important;
}
.avatarBox {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 1000px;
    img {
        width: 80px !important;
        height: auto;
    }
}
.avatar_box {
    img {
        height: 100px !important;
    }
    .upload-el {
        margin: 0 !important;
    }
}
.avatar_tips {
    span {
        font-size: 12px !important;
    }
}
.user_introduce_box {
    .el-col {
        margin-bottom: 10px !important;
        .el-form-item__label {
            text-align: right !important;
        }
    }
}
.zs {
    clear: both;
    padding-top: 20px;
    .add_top_title {
        width: 100%;
        margin-right: 46px;
    }
    .up-certificate-list {
        width: 100%;
        .el-row {
            height: 40px;
            line-height: 40px;
        }
    }
}
.el-image {
    height: 600px !important;
    width: auto;
    img {
        height: 600px !important;
        width: auto;
    }
}
.add_form {
    .el-col {
        width: 50%;
        padding: 0px !important;
        margin: 0px !important;
    }
    .el-col-24 {
        width: 100%;
    }
    .el-col-10,
    .el-col-11 {
        height: 60px;
    }
    .el-col-8 {
        width: 40%;
    }
    .el-col-3 {
        width: 20%;
    }
    .formCertificate {
        .el-col-24 {
            height: 66px;
        }
    }
}
</style>