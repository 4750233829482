<template>
    <div class="attestation">
        <div class="white-bg">
            <section class="secton attestation-sec">
                <div class="form-panner base-info-panner">
                    <header class="panner-hd">
                        <h3 class="main-tit" v-if="attestation_type==1">个人工程极客入驻</h3>
                        <h3 class="main-tit" v-if="attestation_type==2">工作室工程极客入驻</h3>
                        <h3 class="main-tit" v-if="attestation_type==3">单位工程极客入驻</h3>
                    </header>
                </div>
            </section>

            <edit-person
                :attestation="attestationInfo"
                v-if="attestation_type==1"
                @onSave="onSave"
            />

            <edit-studios
                :attestation="attestationInfo"
                v-if="attestation_type==2"
                @onSave="onSave"
            />

            <edit-unit :attestation="attestationInfo" v-if="attestation_type==3" @onSave="onSave" />
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import editPerson from "./module/person.vue";
import editStudios from "./module/studios.vue";
import editUnit from "./module/unit.vue";
export default {
    components: {
        editPerson,
        editStudios,
        editUnit
    },
    data() {
        return {
            attestation_type: 0, //认证类型存在，入驻类型（0.未认证。1.个人认证。2.工作室认证。3.单位认证。）
            attestationInfo: {}
        };
    },
    computed: {
        ...mapState(["userInfo"]) //获取用户信息
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),//刷新用户数据方法
        onSave(obj) {
            //更新认证信息
            if (obj.option == "update") {
                this.ajaxUserInfo();
                sessionStorage.removeItem("user_attestation_info"); //清空缓存
            } else if (obj.option == "change") {
                //缓存用户认证变更信息
                sessionStorage.setItem("user_attestation_info", JSON.stringify(obj.data)); //设置更改缓存
            }
        }
    },
    created() {
        //入驻状态（0.未入驻；1.待审核；2.已入驻；3.审核失败；）
        if (this.userInfo.info.attestation == 1 || this.userInfo.info.attestation == 2) {
            this.$router.push("/user/index"); //跳转到用户中心
        }

        //获取缓存用户输入
        let sessionAttestation = sessionStorage.getItem("user_attestation_info");
        if (sessionAttestation) {
            this.attestationInfo = JSON.parse(sessionAttestation); //如果有缓存从缓存取，展示用户填写的更改信息，防止用户填写信息后丢失
        } else {
            this.attestationInfo = this.userInfo.attestation; //没有缓存就从数据库接口取初始化信息
        }

        //入驻类型（0.未入驻；1.个人入驻；2.工作室入驻；3.单位入驻；）
        if (this.userInfo.info.attestation_type) {
            this.attestation_type = this.userInfo.info.attestation_type; //根据用户认证类型，打开对应的认证信息页面
        } else {
            //新增认证
            let type = this.$route.query.type;
            this.attestation_type = type; //打开对应的认证页面，入驻类型（0.未认证。1.个人认证。2.工作室认证。3.单位认证。）
            if (!type) {
                this.$router.push({ name: "userIndex" });
            }
        }
    }
};
</script>
</script>
<style lang="less" scoped>
@import "../../../../styles/ucattestation.less";
</style>
