<template>
    <div class="form-panner-bd bi-bd" v-loading="loading">
        <div class="form-cont" :class="{'border-none':activeStepNum==2 }">
            <el-form
                ref="form"
                :model="formData"
                :rules="rules"
                label-width="145px"
                label-position="left"
            >
                <el-row :gutter="20">
                    <div class="form-basisinfo steps steps_box" v-if="activeStepNum==1">
                        <!--  第一步 填写基本信息  -->
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">单位</span>信息
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="单位名称" prop="name">
                                        <el-input
                                            v-model="formData.name"
                                            clearable
                                            placeholder="请输入名称"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="单位类型" prop="organ_type">
                                        <el-select
                                            v-model="formData.organ_type"
                                            placeholder="请选择"
                                            @change="change"
                                        >
                                            <el-option
                                                v-for="item in organ_types"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <!--  如果是 行政机关或者事业单位时 需要填写 组织单位代码 否则隐藏组织单位代码 -->
                                <el-col :span="24">
                                    <el-form-item label="- 统一社会信用代码">
                                        <el-input
                                            v-model="formData.organizational_code"
                                            clearable
                                            placeholder="请填写"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item
                                        :label="formData.organ_type==3?'营业执照':(formData.organ_type==2?'机构代码证':(formData.organ_type==1?'单位证书':'上传证件'))"
                                        prop="organ_file"
                                    >
                                        <el-input
                                            v-if="formData.organ_file"
                                            class="up-disable-ipt"
                                            :value="formData.organ_file?'证件上传成功':'请上传证件'"
                                            clearable
                                            :disabled="true"
                                            placeholder="请上传证件照片"
                                        ></el-input>

                                        <imgUpload @onupload="onupload">
                                            <el-link
                                                icon="jk-icon-shangchuan"
                                                slot="trigger"
                                                type="primary"
                                                :underline="false"
                                            >{{ formData.organ_file?'重新上传':'上传图片' }}</el-link>
                                            <div slot="tip" class="el-upload__tip yellow">小于5M</div>
                                        </imgUpload>

                                        <div
                                            style="width:100%;height:100px;overflow:hidden;"
                                            v-if="formData.organ_file"
                                        >
                                            <el-image
                                                style="margin-top:20px;width:100px;height:auto;"
                                                :src="util.reImg(formData.organ_file)"
                                            ></el-image>
                                        </div>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item label="法人姓名" prop="legal_name">
                                        <el-input
                                            v-model="formData.legal_name"
                                            clearable
                                            placeholder="请输入法人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item label="法人身份证号" prop="legal_idcard">
                                        <el-input
                                            v-model="formData.legal_idcard"
                                            clearable
                                            placeholder="请输入法人身份证号"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </div>
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">联系</span>人信息
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="联系人姓名" prop="contacts_name">
                                        <el-input
                                            v-model="formData.contacts_name"
                                            clearable
                                            placeholder="请输入联系人姓名"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="联系人身份证号码" prop="contacts_idcard">
                                        <el-input
                                            v-model="formData.contacts_idcard"
                                            clearable
                                            placeholder="请输入联系人身份证号码"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="联系人电话" prop="contacts_phone">
                                        <el-input
                                            v-model="formData.contacts_phone"
                                            clearable
                                            placeholder="请输入联系人电话"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="联系人邮箱" prop="contacts_email">
                                        <el-input
                                            v-model="formData.contacts_email"
                                            clearable
                                            placeholder="请输入联系人邮箱"
                                            @blur="local_save"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </div>
                    </div>

                    <div class="info-inner" v-if="activeStepNum==2">
                        <div class="info-card-panner border-0">
                            <div class="panner-bd">
                                <div class="tips-inner">
                                    <span class="icon-wrap">
                                        <i class="jk-icon-gou icon"></i>
                                    </span>
                                    <p class="tips">提交成功</p>
                                    <p class="desc">
                                        您所填写的资料已经顺利提交，审核结果将在
                                        <span class="yellow" v-text="passDate"></span>以短信方式通知您，请耐心等待。
                                    </p>
                                    <router-link to="/user/index">
                                        <el-button>返回个人中心</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <el-col :span="24" v-if="activeStepNum==1">
                        <el-checkbox v-model="isReadRead" class="check">
                            我已阅读并同意
                            <span style="cursor:pointer;color:#409EFF;" @click="showArticleDetail">《询龙网平台服务协议》</span>
                        </el-checkbox>
                        <div class="cont-btn center">
                            <el-button type="primary" :disabled="!isReadRead" @click="save">提交认证</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <el-dialog title="询龙网平台服务协议" :visible.sync="dialogAgreement">
            <div v-html="articleContent"></div>
        </el-dialog>
    </div>
</template>
<script>
import imgUpload from "@/components/upload/imgUpload.vue";
export default {
    components: {
        imgUpload
    },
    props: ["attestation"],
    data() {
        return {
            dialogAgreement: false, //用户协议弹出框
            articleContent: "",
            activeStepNum: 1,
            isReadRead: false,
            pictureUP: "",
            pictureDown: "",
            passDate: "",
            loading: false,
            sexs: [
                {
                    value: 1,
                    label: "男"
                },
                {
                    value: 2,
                    label: "女"
                }
            ],
            organ_types: [
                {
                    value: 1,
                    label: "事业单位"
                },
                {
                    value: 2,
                    label: "行政机关"
                },
                {
                    value: 3,
                    label: "企业单位"
                }
            ],
            formData: {
                employer_type: 2,
                organ_type: {
                    value: "1",
                    label: "事业单位"
                },
                name: "",
                nick: "",
                idcard: "",
                picture: "",
                organizational_code: "",
                organ_file: "",
                legal_name: "",
                legal_idcard: "",
                contacts_name: "",
                contacts_idcard: "",
                contacts_phone: "",
                contacts_email: ""
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入姓名",
                    trigger: ["change", "blur"]
                },
                organ_type: {
                    required: true,
                    message: "请选择雇主类型",
                    trigger: ["change", "blur"]
                },
                organizational_code: {
                    required: true,
                    message: "请填写统一社会信用代码",
                    trigger: ["change", "blur"]
                },
                organ_file: {
                    required: true,
                    message: "请上传营业执照",
                    trigger: ["change", "blur"]
                },
                legal_name: {
                    required: true,
                    message: "请填写法人姓名",
                    trigger: ["change", "blur"]
                },
                legal_idcard: [
                    {
                        required: true,
                        message: "请输入法人身份证号码",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: this.validata.idCard(),
                        trigger: ["change", "blur"]
                    }
                ],
                contacts_name: {
                    required: true,
                    message: "请填写联系人姓名",
                    trigger: ["change", "blur"]
                },
                contacts_idcard: [
                    {
                        required: true,
                        message: "请输入联系人身份证号码",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: this.validata.idCard(),
                        trigger: ["change", "blur"]
                    }
                ],
                contacts_phone: [
                    {
                        required: true,
                        message: "请输入联系人电话",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                contacts_email: [
                    {
                        required: true,
                        message: "请输入联系人邮箱",
                        trigger: ["change", "blur"]
                    },
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur", "change"]
                    }
                ]
            }
        };
    },
    methods: {
        onupload(data) {
            this.formData.organ_file = data.file;
            this.local_save();
        },
        change(t) {
            if (t == 3) {
                // this.rules.organizational_code.required = true;
                this.rules.organ_file.message = "请上传营业执照";
            } else if (t == 2) {
                // this.rules.organizational_code.required = false;
                // this.formData.organizational_code = "";
                this.rules.organ_file.message = "请上传机构代码证";
            } else {
                // this.rules.organizational_code.required = false;
                // this.formData.organizational_code = "";
                this.rules.organ_file.message = "请上传单位证书";
            }
            this.local_save();
        },
        save() {
            let _this = this;
            _this.$refs["form"].validate(valid => {
                if (valid) {
                    _this.loading = true;
                    _this.post(
                        "/user/employer/attestation",
                        _this.formData,
                        data => {
                            _this.loading = false;
                            if (200 == data.code) {
                                sessionStorage.removeItem(
                                    "employer_atte_form_data"
                                );
                                _this.activeStepNum = 2;
                                _this.passDate = data.data.review_time;
                                _this.$emit("onSave", data.data);
                            } else {
                                _this.$message.error(data.msg);
                            }
                        }
                    );
                }
            });
            return false;
        },

        //获取文章详情
        goArticleDetail() {
            var _this = this;
            _this.post("/article/index/detail", { id: 2 }, function (res) {
                if (res.code == 200) {
                    _this.articleContent = res.data.content;
                }
            });
        },

        //弹出雇主认证条例
        showArticleDetail() {
            this.dialogAgreement = true;
        },

        //缓存表单
        local_save() {
            sessionStorage.setItem(
                "employer_atte_form_data",
                JSON.stringify(this.formData)
            );
        }
    },
    created() {
        this.goArticleDetail(); //获取文章详情

        let _data = sessionStorage.getItem("employer_atte_form_data");
        if (_data) {
            this.formData = JSON.parse(_data);
        } else {
            if (this.attestation.name) {
                let _data = this.attestation;
                if (_data.picture) {
                    this.pictureUP = _data.picture[0];
                    this.pictureDown = _data.picture[1];
                    _data.picture = _data.picture.join(",");
                }
                this.formData = this.attestation;
                if (this.attestation.organ_type == 3) {
                    this.rules.organizational_code.required = true;
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.panner-bd {
    overflow: hidden;
}
.center {
    margin-top: 40px;
    text-align: center;
}
.check {
    margin: 10px 60px;
}
.steps_box {
    .el-col {
        margin-bottom: 10px;
    }
}
</style>

