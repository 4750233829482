<template>
    <div class="info-card-panner">
        <header class="panner-hd">
            <el-link @click="on_add" type="primary" class="pull-right" icon="jk-icon-tianjia">添加证书</el-link>
            <h2 class="tit">
                <span class="line">所获</span>证书
            </h2>
        </header>
        <div class="panner-bd user-introduce user_introduce">
            <div class="add-form" v-if="isEdit">
                <el-form
                    ref="form"
                    :model="formData"
                    :rules="rules"
                    label-width="105px"
                    label-position="left"
                >
                    <el-col :span="24">
                        <el-form-item label="证书名称" prop="title">
                            <el-input
                                class="width-100"
                                v-model="formData.title"
                                clearable
                                placeholder="请输入证书名称"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="证书编号" prop="code">
                            <el-input v-model="formData.code" clearable placeholder="请输入证书编号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="获得时间" prop="time">
                            <el-date-picker
                                v-model="formData.time"
                                type="month"
                                :picker-options="datePicker"
                                placeholder="选择获得证书的时间"
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传证件" prop="image">
                            <el-input
                                v-if="formData.image"
                                class="up-disable-ipt"
                                v-model="formData.image"
                                clearable
                                :disabled="true"
                                placeholder="请上传证件照片"
                            ></el-input>
                            <only-upload @onupload="onupload" :before-upload="before_upload">
                                <el-link
                                    icon="jk-icon-shangchuan"
                                    slot="trigger"
                                    type="primary"
                                    :underline="false"
                                >{{ formData.image?'重新上传':'上传图片' }}</el-link>

                                <div slot="tip" class="el-upload__tip">小于5M</div>
                            </only-upload>
                            <div v-if="formData.image" style="text-align:left;">
                                <el-image style="width:150px;height:auto;border-radius:6px;margin-top:10px;" :src="util.reImg(formData.image)"></el-image>
                            </div>

                            <div class="cont-btn add-cer pull-right">
                                <el-button
                                    @click="save"
                                    type="primary"
                                >{{formData.index>-1?'确认保存':'确认添加'}}</el-button>
                                <el-button @click="isEdit=false">取消</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
            <div class="achievement-items-wraper">
                <el-row
                    class="achievement-list-item-inner"
                    v-for="(item,index) in value"
                    :key="index"
                >
                    <el-col :span="18">{{item.title}}</el-col>
                    <el-col :span="4">{{item.time}}</el-col>
                    <el-col class="edit-col" :span="2">
                        <el-link
                            icon="jk-icon-bianjilanse el-icon-m"
                            :underline="false"
                            @click="upadta(index)"
                        ></el-link>
                        <el-link
                            icon="jk-icon-lajitong el-icon-m"
                            :underline="false"
                            @click="del(index)"
                        ></el-link>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import onlyUpload from "@/components/upload/imgUpload.vue";
import { formatdata } from "@/libs/filter.js";
export default {
    props: ['value'],
    components: {
        onlyUpload
    },
    data() {
        return {
            isEdit: false,
            datePicker: this.processDate(),
            formData: {
                title: "",
                code: "",
                time: "",
                image: ""
            },
            rules: {
                title: {
                    required: true,
                    message: "请输入证书名称",
                    trigger: ["change", "blur"]
                },
                code: {
                    required: true,
                    message: "请输入证书编号",
                    trigger: ["change", "blur"]
                },
                time: {
                    required: true,
                    message: "请选择获得时间",
                    trigger: ["change", "blur"]
                },
                image: {
                    required: true,
                    message: "请上传证件",
                    trigger: ["change", "blur"]
                }
            }
        };
    },
    methods: {
        processDate() {
            return {
                disabledDate(time) {
                    if (time.getTime() > Date.now()) {
                        return true;
                    }
                }
            };
        },
        on_add() {
            this.formData = {
                title: "",
                code: "",
                time: "",
                image: ""
            };
            this.isEdit = true;
        },
        //证书新增
        save() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.isEdit = false;
                    let _list = this.value ? this.value : [];
                    let _object = this.formData;
                    _object.time = formatdata(
                        _object.time.getTime(),
                        "yyyy-MM"
                    );
                    if (_object.index > -1) {
                        let _edit = _object;
                        _list[_object.index] = {
                            title: _object.title,
                            code: _object.code,
                            time: _object.time,
                            image: _object.image
                        };
                    } else {
                        _list.push(_object);
                    }
                    this.$emit("input", _list);
                }
            });
        },

        //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },

        //证书编辑
        upadta(i) {
            let _edit = JSON.parse(
                JSON.stringify(this.value.find((c, index) => index == i))
            );
            _edit["index"] = i;
            if (_edit.time) {
                _edit.time = new Date(_edit.time);
            }
            this.formData = _edit;
            this.isEdit = true;
        },
        del(i) {
            let _list = this.value.filter((c, index) => index != i);
            this.$emit("input", _list);
        },
        onupload(data) {
            this.formData.image = data.file;
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.user_introduce {
    .el-col {
        margin-bottom: 8px;
    }
}
</style>