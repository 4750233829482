<template>
    <geek-attest-edit v-if="userInfo.info.user_type==1" />
    <employer-attest-edit v-else />
</template>
<script>
import { mapState } from "vuex";
import geekAttestEdit from "./geek/edit.vue";
import employerAttestEdit from "./employer/edit.vue";
export default {
    components: {
        geekAttestEdit,
        employerAttestEdit
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>