
<template>
    <div class="form-panner-bd bi-bd"
         v-loading="loading">
        <div class="steps-wraper">
            <!-- 步骤 -->
            <el-steps :active="activeStepNum"
                      align-center>
                <el-step title="填写基本信息"></el-step>
                <el-step title="添加人员信息"></el-step>
                <el-step title="填写业绩信息"></el-step>
                <el-step title="提交入驻"></el-step>
            </el-steps>
        </div>
        <div class="form-cont"
             :class="{'border-none':activeStepNum==5}">
            <el-row :gutter="20">
                <div class="form-basisinfo steps steps_box"
                     v-show="activeStepNum==1">
                    <el-form ref="form1"
                             :model="formData"
                             :rules="step.rules1"
                             label-width="125px"
                             label-position="left">
                        <!--  第一步 填写基本信息  -->
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">工作</span>室信息
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="工作室名称"
                                                  prop="name"
                                                  name="name">
                                        <!-- 注，验证时，prop和name 的值必须相等 -->
                                        <el-input v-model="formData.name"
                                                  clearable
                                                  placeholder="请输入工作室名称"
                                                  :autofocus="true"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="工作室地址"
                                                  prop="area"
                                                  name="area">
                                        <el-cascader ref="areaList"
                                                     :options="areaList"
                                                     v-model="formData.area"
                                                     :props="areaProps"
                                                     separator="-"
                                                     @blur="local_save"></el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label
                                                  prop="address"
                                                  name="address">
                                        <el-input class="width-100"
                                                  placeholder="详细地址。如xxx小区x栋x单元xxx号"
                                                  v-model="formData.address"
                                                  clearable
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="负责人姓名"
                                                  prop="legal_name">
                                        <el-input v-model="formData.legal_name"
                                                  clearable
                                                  placeholder="请输入负责人姓名"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="负责人身份证"
                                                  prop="legal_idcard">
                                        <el-input v-model="formData.legal_idcard"
                                                  clearable
                                                  placeholder="请输入身份证号码"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="负责人电话"
                                                  prop="legal_mobile">
                                        <el-input v-model="formData.legal_mobile"
                                                  clearable
                                                  placeholder="请输入负责人电话"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="职业资格证书"
                                                  prop="legal_credentials">
                                        <el-input v-if="formData.legal_credentials"
                                                  class="up-disable-ipt"
                                                  :value="formData.legal_credentials?'证件上传成功':'请上传证件'"
                                                  clearable
                                                  :disabled="true"
                                                  placeholder="请上传证件照片"></el-input>

                                        <only-upload @onupload="onupload" :before-upload="before_upload">
                                            <el-link icon="jk-icon-shangchuan"
                                                     slot="trigger"
                                                     type="primary"
                                                     :underline="false">{{ formData.legal_credentials?'重新上传':'上传图片' }}</el-link>

                                            <div slot="tip"
                                                 class="el-upload__tip">小于5M</div>
                                        </only-upload>

                                        <div style="width:100%;height:120px;overflow:hidden;"
                                             v-if="formData.legal_credentials">
                                            <el-image style="margin-top:20px;width:100px;height:auto;"
                                                      :src="util.reImg(formData.legal_credentials)"></el-image>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </div>
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">联系</span>人信息
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="联系人姓名"
                                                  prop="contacts_name">
                                        <el-input v-model="formData.contacts_name"
                                                  clearable
                                                  placeholder="请输入联系人姓名"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="联系人电话"
                                                  prop="contacts_mobile">
                                        <el-input v-model="formData.contacts_mobile"
                                                  clearable
                                                  placeholder="请输入联系人电话"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="联系人邮箱"
                                                  prop="contacts_email">
                                        <el-input v-model="formData.contacts_email"
                                                  clearable
                                                  placeholder="请输入联系人邮箱"
                                                  @blur="local_save"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </div>
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">工作</span>室能力
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <el-col :span="24">
                                    <el-form-item label="能力标签"
                                                  prop="ability_tags">
                                        <el-tag v-for="(tag,index) in formData.ability_tags"
                                                :key="index"
                                                closable
                                                :disable-transitions="false"
                                                @close="CloseTag(tag)">{{tag}}</el-tag>
                                        <el-button type="text"
                                                   class="button-new-tag"
                                                   size="small"
                                                   @click="showInputTag">+添加</el-button>
                                        <span class="max-tips">最多可添加三个</span>
                                    </el-form-item>
                                </el-col>
                                <transition name="el-fade-in-linear">
                                    <div class="add-tag"
                                         v-show="tags.inputVisible">
                                        <el-col :span="24">
                                            <el-form-item label>
                                                <el-select class="tags-sel-opt"
                                                           v-model="tags.selectValue"
                                                           placeholder="请选择技能">
                                                    <el-option v-for="(item,index) in tags.list"
                                                               :key="index"
                                                               :label="item.label"
                                                               :value="item.value"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label>
                                                <el-input class="tags-sel-ipt"
                                                          v-model="tags.inputValue"
                                                          clearable
                                                          placeholder="其他"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label>
                                                <el-button class="add-confirm-btn"
                                                           type="primary"
                                                           @click="addTag">确认添加</el-button>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </el-form>
                </div>

                <div class="step2-info steps"
                     v-show="activeStepNum==2">
                    <div class="info-inner">
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    <span class="line">工作</span>室简介
                                </h2>
                            </header>
                            <div class="panner-bd user-introduce">
                                <quill-editor v-model="formData.introduction"
                                              style="height: 500px;"
                                              @blur="local_save"
                                              @change="$event.quill.deleteText(2000, 1, formData.introduction)"
                                              :options="{placeholder: '请输入工作室介绍，内容不超过2000个文字'}"></quill-editor>

                                <el-link class="for-example"
                                         type="primary"
                                         :underline="false">查看示例</el-link>
                            </div>
                        </div>
                    </div>
                    <div class="info-inner">
                        <add-personal v-model="formData.personal"
                                      @input="local_save" />
                    </div>
                </div>

                <div class="step3-info steps"
                     v-show="activeStepNum==3">
                    <div class="info-inner">
                        <add-achievement v-model="formData.achievement"
                                         :type="2"
                                         @input="local_save" />
                    </div>
                </div>

                <div class="step4-info steps agreement-step"
                     v-show="activeStepNum==4">
                    <div class="info-inner">
                        <div class="info-card-panner">
                            <header class="panner-hd">
                                <h2 class="tit">
                                    询龙网平台服务协议
                                </h2>
                            </header>
                            <div class="panner-bd"
                                 v-html="articleContent"></div>
                            <div class="panner-footer">
                                <el-checkbox v-model="isReadRead">
                                    我已阅读并同意
                                    <span style="cursor:pointer;">《询龙网平台服务协议》</span>
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="step5-info steps last-steps"
                     v-show="activeStepNum==5">
                    <div class="info-inner">
                        <div class="info-card-panner">
                            <div class="panner-bd">
                                <div class="tips-inner">
                                    <span class="icon-wrap">
                                        <i class="jk-icon-gou icon"></i>
                                    </span>
                                    <p class="tips">提交成功</p>
                                    <p class="desc">
                                        您所填写的资料已经顺利提交，审核结果将在
                                        <span class="yellow"
                                              v-text="review_time"></span>以短信方式通知您，请耐心等待。
                                    </p>
                                    <router-link to="/user/index">
                                        <el-button>返回个人中心</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <el-col :span="24"
                        v-if="activeStepNum!=5">
                    <div class="cont-btn pull-right">
                        <el-button @click="previous"
                                   v-if="activeStepNum!=1 || !formData.name">上一步</el-button>
                        <el-button v-if="activeStepNum<4"
                                   type="primary"
                                   @click="next">下一步</el-button>
                        <el-button v-else-if="activeStepNum==4"
                                   @click="save"
                                   type="primary"
                                   :disabled="!isReadRead">提交认证</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import addAchievement from "./add-achievement.vue";
import addPersonal from "./add-personal.vue";
import onlyUpload from "@/components/upload/only-upload.vue";
import { formatdata } from "@/libs/filter.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
    name: "ucIndex",

    components: {
        addAchievement,
        addPersonal,
        onlyUpload,
        quillEditor
    },

    props: ["attestation"],

    data() {
        let _this = this;
        return {
            activeStepNum: 1, // 当前到哪一步
            isReadRead: false, // 是否阅读注册条款
            articleContent: "", //工程极客入驻条例内容
            review_time: "",
            loading: false,
            sexs: [
                {
                    value: "1",
                    label: "男"
                },
                {
                    value: "2",
                    label: "女"
                }
            ],
            tags: {
                list: [],
                inputVisible: false,
                selectValue: "",
                inputValue: ""
            },
            areaList: [],
            areaProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    _this.load_area(node.value, data => {
                        let _list = data.map(c => {
                            c["leaf"] = node.level >= 2;
                            return c;
                        });
                        resolve(data);
                    });
                }
            },
            step: {
                rules1: {
                    name: {
                        required: true,
                        message: "请输入工作室名称",
                        trigger: ["change", "blur"]
                    },
                    area: {
                        required: true,
                        message: "请选择工作地址",
                        trigger: ["change", "blur"]
                    },
                    legal_name: {
                        required: true,
                        message: "请输入负责人姓名",
                        trigger: ["change", "blur"]
                    },
                    legal_idcard: [
                        {
                            required: true,
                            message: "请输入负责人身份证",
                            trigger: ["change", "blur"]
                        },
                        {
                            validator: _this.validata.idCard(),
                            trigger: ["change", "blur"]
                        }
                    ],
                    legal_mobile: [
                        {
                            required: true,
                            message: "请输入负责人电话",
                            trigger: ["change", "blur"]
                        },
                        {
                            validator: _this.validata.mobile(),
                            trigger: ["change", "blur"]
                        }
                    ],
                    legal_credentials: {
                        required: true,
                        message: "请上传职业资格证书",
                        trigger: ["change", "blur"]
                    },
                    contacts_name: {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: ["change", "blur"]
                    },
                    contacts_mobile: [
                        {
                            required: true,
                            message: "请输入联系人电话",
                            trigger: ["change", "blur"]
                        },
                        {
                            validator: _this.validata.mobile(),
                            trigger: ["change", "blur"]
                        }
                    ],
                    contacts_email: [
                        {
                            required: true,
                            message: "请输入联系人邮箱",
                            trigger: ["change", "blur"]
                        },
                        {
                            type: "email",
                            message: "请输入正确的邮箱地址",
                            trigger: ["blur", "change"]
                        }
                    ]
                }
            },
            formData: {
                geek_type: 2, //工作室认证
                organ_type: 0,
                name: "",
                area: [],
                address: "",
                legal_name: "",
                legal_idcard: "",
                legal_mobile: "",
                legal_credentials: "",
                contacts_name: "",
                contacts_mobile: "",
                contacts_email: "",
                ability_tags: [],
                introduction: "",
                personal: [],
                achievement: []
            }
        };
    },

    methods: {
        //加载区域列表
        load_area(id, call) {
            this.post("/system/area/childrens", { pid: id }, data => {
                if (200 == data.code) {
                    let _list = data.data.map(c => ({
                        value: c.id,
                        label: c.name
                    }));
                    call(_list);
                }
            });
        },

        //获取工程极客入驻条例内容
        getArticleContent() {
            let _this = this;
            this.post("/article/index/detail", { id: 2 }, data => {
                if (data.code == 200) {
                    _this.articleContent = data.data.content;
                }
            });
        },

        //标签相关操作
        load_tags() {
            this.post("/user/index/tag_list", {}, data => {
                if (200 == data.code) {
                    let _list = data.data.map(c => ({
                        value: c.name,
                        label: c.name
                    }));
                    this.tags.list = _list;
                }
            });
        },
        showInputTag() {
            this.tags.inputVisible = true;
        },
        CloseTag(tag) {
            this.formData.ability_tags = this.formData.ability_tags.filter(
                c => c != tag
            );
            this.local_save(); //添加表单缓存
        },
        addTag() {
            if (this.formData.ability_tags.length < 3) {
                if (!this.tags.selectValue && !this.tags.inputValue) {
                    this.$message.error("请选择能力标签");
                    return;
                } else if (this.tags.inputValue) {
                    if (
                        this.formData.ability_tags.indexOf(
                            this.tags.inputValue
                        ) > -1
                    ) {
                        this.$message.error("您已添加过此能力标签");
                        return;
                    }
                    this.formData.ability_tags.push(this.tags.inputValue);
                } else {
                    if (
                        this.formData.ability_tags.indexOf(
                            this.tags.selectValue
                        ) > -1
                    ) {
                        this.$message.error("您已添加过此能力标签");
                        return;
                    }
                    this.formData.ability_tags.push(this.tags.selectValue);
                }
                this.tags.inputValue = "";
                this.tags.selectValue = "";
            } else {
                this.$message.error("最多只能添加3个能力标签");
                return;
            }
            this.local_save(); //缓存表单信息
        },

        //上一步按钮
        previous() {
            if (this.activeStepNum > 1) {
                this.activeStepNum--;
            } else {
                this.$router.push("/user/attest"); //跳转认证首页
            }
        },

        //下一步按钮
        next() {
            if (this.activeStepNum == 1) {
                //验证表单填写
                this.$refs["form1"].validate(valid => {
                    if (valid) {
                        this.activeStepNum++;
                    }
                });
            } else {
                this.activeStepNum++; //不验证下一步下一步
            }
            this.local_save(); //添加表单缓存
        },

        //上传职业资格证书
        onupload(data) {
            this.formData.legal_credentials = data.file;
            this.local_save(); //缓存表单信息
        },

        //缓存表单信息
        local_save() {
            this.$emit("onSave", { option: "change", data: this.formData }); //提交到父组件，缓存表单值变更
        },

         //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },

        //提交认证信息保存
        save() {
            let _this = this;
            let _formData = this.formData;

            let has_achievement = 0;
            if (_formData.achievement instanceof Array) {
                _formData.achievement.forEach(function (item) {
                    if (item["title"]) {
                        has_achievement = 1;
                        return;
                    }
                });
            }
            if (has_achievement == 1) {
                _formData.achievement = _formData.achievement.map(c => c.id); //从业绩对象提出业绩id数组
            }

            let has_personal = 0;
            if (_formData.personal instanceof Array) {
                _formData.personal.forEach(function (item) {
                    if (item["name"]) {
                        has_personal = 1;
                        return;
                    }
                });
            }
            if (has_personal == 1) {
                _formData.personal = _formData.personal.map(c => c.id); //从人员对象提出业绩id数组
            }

            //提交认证信息保存
            _this.loading = true;
            _this.post("/user/geek/attestation", _formData, data => {
                _this.loading = false;
                if (data.code == 200) {
                    _this.review_time = data.data.review_time; //审核时间
                    _this.activeStepNum = 4; //跳转到操作认证结果页面
                    _this.$emit("onSave", {
                        option: "update",
                        data: data.data
                    }); //提交到父组件更新用户信息
                    _this.$router.push("/user/attest/success");
                } else {
                    _this.$message.error(data.msg);
                    return;
                }
            });
        }
    },

    created() {
        if (this.attestation.name) {
            this.formData = this.attestation; //把父组件传进来的认证信息给表单
            this.formData.ability_tags = this.attestation.ability_tags
                ? this.attestation.ability_tags
                : [];
        }
        this.getArticleContent(); //获取极客入驻内容
        this.load_area(0, data => {
            this.areaList = data;
        }); //加载区域
        this.load_tags(); //加载标签
    }
};
</script>
<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.panner-bd {
    overflow: hidden;
}
.step3-info .panner-bd {
    height: auto !important;
}
.steps_box {
    .el-col {
        margin-bottom: 6px !important;
    }
}
</style>