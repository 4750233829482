<template>
    <div class="form-panner-bd bi-bd" v-loading="loading">
        <div class="steps-wraper">
            <!-- 步骤 -->
            <el-steps :active="activeStepNum" align-center>
                <el-step title="填写基本信息"></el-step>
                <el-step title="完善专业信息"></el-step>
                <el-step title="提交入驻"></el-step>
            </el-steps>
        </div>
        <div class="form-cont" :class="{'border-none':activeStepNum==4}">
            <el-row :gutter="20">
                <div class="form-basisinfo steps steps_box" v-show="activeStepNum==1">
                    <!--  第一步 填写基本信息  -->
                    <el-form
                        ref="form1"
                        :model="formData"
                        :rules="step.rules1"
                        label-width="125px"
                        label-position="left"
                    >
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input
                                    v-model="formData.name"
                                    clearable
                                    placeholder="请输入姓名"
                                    @blur="local_save"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="身份证号码" prop="idcard">
                                <el-input
                                    v-model="formData.idcard"
                                    @blur="idcardInputWatchEvent"
                                    clearable
                                    placeholder="请输入身份证号码"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="性别" prop="sex">
                                <el-select
                                    v-model="formData.sex"
                                    placeholder="选择性别"
                                    @change="local_save"
                                >
                                    <el-option
                                        v-for="(item,index) in sexs"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="出生年月" prop="birthday">
                                <el-date-picker
                                    v-model="formData.birthday"
                                    type="month"
                                    placeholder="选择出生年月"
                                    :disabled="true"
                                    :picker-options="datePicker"
                                    @blur="local_save"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="毕业院校" prop="college">
                                <el-input
                                    v-model="formData.college"
                                    clearable
                                    placeholder="请输入毕业院校"
                                    @blur="local_save"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="参加工作年月" prop="experience_date" class="pull-left">
                                <el-date-picker
                                    v-model="formData.experience_date"
                                    type="month"
                                    placeholder="选择参加工作年月"
                                    :picker-options="datePicker"
                                    :disabled="experience_date_disabled"
                                    @change="local_save"
                                ></el-date-picker>
                            </el-form-item>

                            <el-form-item label class="pull-left no-workMonth">
                                <el-checkbox v-model="experience" @change="change">暂无工作经历</el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="工作地址" prop="area">
                                <el-cascader
                                    ref="areas"
                                    :options="areas"
                                    v-model="formData.area"
                                    :props="props"
                                    separator="-"
                                    @change="local_save"
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label>
                                <el-input
                                    class="width-100"
                                    placeholder="详细地址。如xxx小区x栋x单元xxx号"
                                    v-model="formData.address"
                                    clearable
                                    @blur="local_save"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="- 能力标签" prop="ability_tags">
                                <el-tag
                                    v-for="(tag,index) in formData.ability_tags"
                                    :key="index"
                                    closable
                                    :disable-transitions="false"
                                    @close="CloseTag(tag)"
                                >{{tag}}</el-tag>
                                <el-button
                                    type="text"
                                    class="button-new-tag"
                                    size="small"
                                    @click="showInputTag"
                                >+添加</el-button>
                                <span class="max-tips">最多可添加三个</span>
                            </el-form-item>
                        </el-col>
                        <transition name="el-fade-in-linear">
                            <div class="add-tag" v-show="tags.inputVisible">
                                <el-col :span="24">
                                    <el-form-item label>
                                        <el-select
                                            class="tags-sel-opt"
                                            v-model="tags.selectValue"
                                            placeholder="请选择技能"
                                        >
                                            <el-option
                                                v-for="(item,index) in tags.list"
                                                :key="index"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label>
                                        <el-input
                                            class="tags-sel-ipt"
                                            v-model="tags.inputValue"
                                            clearable
                                            placeholder="其他"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label>
                                        <el-button
                                            class="add-confirm-btn"
                                            type="primary"
                                            @click="addTag"
                                        >确认添加</el-button>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </transition>
                    </el-form>
                </div>
                <div class="step2-info steps" v-show="activeStepNum==2">
                    <el-form
                        ref="form2"
                        :model="formData"
                        :rules="step.rules3"
                        label-width="125px"
                        label-position="left"
                    >
                        <div class="info-inner">
                            <div class="info-card-panner" style="padding-bottom:70px;">
                                <header class="panner-hd">
                                    <h2 class="tit">
                                        <span class="line">自我</span>介绍
                                    </h2>
                                </header>
                                <div class="panner-bd user-introduce">
                                    <!-- <el-input
                                        class="introduce-textarea"
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="formData.introduction"
                                        maxlength="500"
                                        show-word-limit
                                    ></el-input>-->
                                    <el-upload
                                        :show-upload-list="false"
                                        :on-success="handleSuccess"
                                        :format="['jpg','jpeg','png','gif']"
                                        :before-upload="handleBeforeUpload"
                                        type="drag"
                                        :action="imgUploadUrl"
                                        class="uploadImage"
                                        :options="editorOption"
                                        ref="QuillEditor">
                                    </el-upload>
                                    <quill-editor
                                        v-model="formData.introduction"
                                        style="height: 500px;"
                                        @blur="local_save"
                                        data-placeholder="123"
                                        :options="editorOption"
                                        @change="$event.quill.deleteText(2000, 1, formData.introduction)"
                                        ref="QuillEditor">
                                    ></quill-editor>

                                    <!-- <el-link
                                        class="for-example"
                                        type="primary"
                                        :underline="false"
                                    >查看示例</el-link>-->
                                </div>
                            </div>
                        </div>
                        <div class="info-inner">
                            <add-achievement v-model="formData.achievement" @input="local_save" />
                            <add-credentials v-model="formData.credentials" @input="local_save" />
                        </div>
                    </el-form>
                </div>
                <div class="step3-info steps agreement-step" v-show="activeStepNum==3">
                    <el-form
                        ref="form3"
                        :model="formData"
                        :rules="step.rules3"
                        label-width="125px"
                        label-position="left"
                    >
                        <div class="info-inner">
                            <div class="info-card-panner">
                                <header class="panner-hd" v-if="dialogAgreement">
                                    <h2 class="tit">
                                        询龙网平台服务协议
                                    </h2>
                                </header>
                                <div class="panner-bd" v-if="dialogAgreement" v-html="articleContent"></div>
                                <div class="panner-footer">
                                    <el-checkbox v-model="isReadRead">
                                        我已阅读并同意
                                        <span style="cursor:pointer;color:#409EFF;">《询龙网平台服务协议》</span>
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="step4-info steps last-steps" v-show="activeStepNum==4">
                    <div class="info-inner">
                        <div class="info-card-panner">
                            <div class="panner-bd">
                                <div class="tips-inner">
                                    <span class="icon-wrap">
                                        <i class="jk-icon-gou icon"></i>
                                    </span>
                                    <p class="tips">提交成功</p>
                                    <p class="desc">
                                        您所填写的资料已经顺利提交，审核结果将在
                                        <span
                                            class="yellow"
                                            v-text="review_time"
                                        ></span>以短信方式通知您，请耐心等待。
                                    </p>
                                    <router-link to="/user/index">
                                        <el-button>返回个人中心</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-col :span="24" v-if="activeStepNum!=4">
                    <div class="cont-btn pull-right">
                        <el-button @click="previous" v-if="activeStepNum!=1 || !formData.id">上一步</el-button>
                        <el-button
                            v-if="activeStepNum<3"
                            type="primary"
                            @click="next"
                            data-num="1"
                        >下一步</el-button>
                        <el-button
                            v-else-if="activeStepNum==3"
                            @click="save"
                            type="primary"
                            :disabled="!isReadRead"
                        >提交认证</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import addAchievement from "./add-achievement.vue";
import addCredentials from "./add-credentials.vue";
import { formatdata } from "@/libs/filter.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 工具栏配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['link', 'image'],
    ['clean']                                        // remove formatting button
];
export default {
    name: "ucIndex",
    components: {
        addAchievement, //添加业绩
        addCredentials, //添加证书
        quillEditor //编辑器，
    },
    props: ['attestation'],
    data() {
        let _this = this;
        return {
            dialogAgreement: true,
            datePicker: _this.processDate(),
            activeStepNum: 1, // 当前到哪一步
            isReadRead: false, // 是否阅读注册条款
            review_time: "",
            experience_date_disabled: false, //参加工作年月是否可用
            articleContent: "", //工程极客入驻条例内容
            loading: false,
            sexs: [
                {
                    value: 1,
                    label: "男"
                },
                {
                    value: 2,
                    label: "女"
                }
            ],
            tags: {
                list: [],
                inputVisible: false,
                selectValue: "",
                inputValue: ""
            },
            areas: [],
            props: {
                lazy: true,
                lazyLoad(node, resolve) {
                    _this.load_area(node.value, data => {
                        let _list = data.map(c => {
                            c["leaf"] = node.level >= 2;
                            return c;
                        });
                        resolve(data);
                    });
                }
            },
            step: {
                rules1: {
                    name: {
                        required: true,
                        message: "请输入姓名",
                        trigger: ["change", "blur"]
                    },
                    idcard: [
                        {
                            required: true,
                            message: "请输入身份证",
                            trigger: ["change", "blur"]
                        },
                        {
                            validator: _this.validata.idCard(),
                            trigger: ["change", "blur"]
                        }
                    ],
                    sex: {
                        required: true,
                        message: "选择性别",
                        trigger: ["change", "blur"]
                    },
                    birthday: [
                        {
                            required: true,
                            message: "请选择出生年月",
                            trigger: ["change", "blur"]
                        }, {
                            validator: function (rule, value, callback) {
                                if (value) {
                                    if (value.toString().indexOf("中国") != -1) {
                                        let _age = parseInt((new Date().getTime() / 86400000) - (value.getTime() / 86400000 + new Date().getDate()));//当前天数-选择天数=已经经过了多少天数
                                        _age = _age / 30 / 12;//算成年
                                        if (_age >= 18 && _age <= 99) {
                                            callback();
                                        } else {
                                            callback(new Error('年龄必须大于18岁及以上'));
                                        }
                                    } else {
                                        callback();
                                    }
                                } else {
                                    callback();
                                }
                            },
                            trigger: ["change", "blur"]
                        }
                    ],
                    college: {
                        required: true,
                        message: "请输入毕业院校",
                        trigger: ["change", "blur"]
                    },
                    experience_date: {
                        required: true,
                        message: "选择参加工作年月",
                        trigger: ["change", "blur"]
                    },
                    area: {
                        required: true,
                        message: "选择工作地址",
                        trigger: ["change", "blur"]
                    },
                    address: {
                        required: true,
                        message: "请输入详细地址",
                        trigger: ["change", "blur"]
                    }
                },
                rules2: {},
                rules3: {}
            },
            experience: false,
            formData: {
                geek_type: 1,//个人极客
                organ_type: 0,
                name: "",
                idcard: "",
                sex: "",
                birthday: "",
                college: "",
                experience: 0,
                experience_date: "",
                area: [],
                address: "",
                ability_tags: [],
                introduction: "",
                achievement: [],
                credentials: []
            },
            imgUploadUrl: "",
            imgHeaders: {
                token: "",
                timestamp: "",
                secret: ""
            },
            contentTips: "contentFontColorGreen",
            editorOption: { 
                placeholder:'您可以在这里填写您专业方面的优势，以便雇主更好地了解您的能力（5000字以内）',
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': (value => {
                                if (value) {
                                document.querySelector('.uploadImage input').click()
                                }else {
                                this.quill.format('image', false);
                                }
                            })
                        }
                    }
                }
            }
        };
    },
    methods: {
        processDate() {
            return {
                disabledDate(time) {
                    if(time.getTime() > Date.now()){
                        return true;
                    }
                }
            };
        },
        handleBeforeUpload(){},
        // 图片
        handleSuccess (res, file) {
                let quill = this.$refs.QuillEditor.quill
                let length = quill.getSelection().index;
                quill.insertEmbed(length, 'image', this.websiteConfigs.sourceUrl + res.data.file)
                quill.setSelection(length + 1)
        },
        getUploadHeader() {
            this.imgUploadUrl =
                this.websiteConfigs.baseUrl +
                this.websiteConfigs.uploadUrl +
                "?group=article"; //获取上传地址
            this.imgHeaders.token = sessionStorage.getItem("token");
            this.imgHeaders.timestamp = new Date().getTime();
            this.imgHeaders.secret = this.websiteConfigs.secret;
        },
        //附件上传
        handleFileSuccess(file, x) {
            if (file.code == -300) {
                this.$message.error("文件太大或不支持的文件格式");
                return false;
            }
            if (this.fileLists.length > 4) {
                this.$message.error("最多只能上传5个附件");
                return false;
            }
            if (file.code == -200) {
                this.$message.error(file.msg);
                return false;
            }
            //上传成功后
            // this.fileLists.push({
            //     hash: file.data.hash,
            //     path: file.data.file,
            //     name: this.fileName,
            //     size: this.converSize(file.data.size),
            //     date: this.converTime(parseInt(file.data.ctime) * 1000)
            // });
        },
        //上传前，获取文件名称
        beforeFileUpload(file, x) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "gif" == suffix ||
                    "zip" == suffix || "rar" == suffix || "7z" == suffix ||
                    "pdf" == suffix || "doc" == suffix || "docx" == suffix || "ppt" == suffix 
                    || "pptx" == suffix || "xls" == suffix || "xlsx" == suffix
                ) { } else {
                    this.$message.error("上传文件格式不正确，请上传jpg，jpeg，png，gif，zip，rar，doc，docx，ppt，pptx，xls，xlsx，pdf等格式的文件");
                    return false;
                }
            }

            this.fileName = file.name;
            this.uploadPress = 0;
        },
        //暂无工作经历checkbox
        change() {
            if (this.experience == true) {
                this.formData.experience_date = ""; //清空参加工作年月
                this.experience_date_disabled = true; //参加工作年月不可用状态
                this.step.rules1.experience_date.required = false; //关闭参加工作年月必填
                this.formData.experience = 0;
            } else {
                this.formData.experience = 1;
                this.formData.experience_date = new Date();
                this.experience_date_disabled = false; //参加工作年月可用状态
                this.step.rules1.experience_date.required = true; //打开参加工作年月必填
            }
            this.local_save();//添加表单缓存
        },

        //加载区域
        load_area(id, call) {
            this.post("/system/area/childrens", { pid: id }, data => {
                if (200 == data.code) {
                    let _list = data.data.map(c => ({
                        value: c.id,
                        label: c.name
                    }));
                    call(_list);
                }
            });
        },

        //加载标签
        load_tags() {
            this.post("/user/index/tag_list", {}, data => {
                if (200 == data.code) {
                    let _list = data.data.map(c => ({
                        value: c.name,
                        label: c.name
                    }));
                    this.tags.list = _list;
                }
            });
        },
        //标签相关操作
        showInputTag() {
            this.tags.inputVisible = true;
        },
        CloseTag(tag) {
            this.formData.ability_tags = this.formData.ability_tags.filter(
                c => c != tag
            );
            this.local_save();//添加表单缓存
        },
        addTag() {
            if (this.formData.ability_tags.length < 3) {
                if (!this.tags.selectValue && !this.tags.inputValue) {
                    this.$message.error("请选择能力标签");
                    return;
                } else if (this.tags.inputValue) {
                    if (this.formData.ability_tags.indexOf(this.tags.inputValue) > -1) {
                        this.$message.error("您已添加过此能力标签");
                        return;
                    }
                    this.formData.ability_tags.push(this.tags.inputValue);
                } else {
                    if (this.formData.ability_tags.indexOf(this.tags.selectValue) > -1
                    ) {
                        this.$message.error("您已添加过此能力标签");
                        return;
                    }
                    this.formData.ability_tags.push(this.tags.selectValue);
                }
                this.tags.inputValue = "";
                this.tags.selectValue = "";
            } else {
                this.$message.error("最多只能添加3个能力标签");
                return;
            }
            this.local_save();//添加表单缓存
        },

        //富文本框字数限制
        alertValue(e, strValue, decimalNum) {
            e.quill.deleteText(decimalNum, 1, strValue);//保留 strValue 的 前 decimalNum 位字符，
        },

        //上一步按钮事件
        previous() {
            if (this.activeStepNum > 1) {
                this.activeStepNum--;
            } else {
                this.$router.push('/user/attest'); //跳转认证首页
            }
        },

        //下一步按钮事件
        next() {
            if (this.activeStepNum == 1) {
                this.$refs["form1"].validate(valid => {
                    if (valid) {
                        this.activeStepNum++; //下一页
                    }
                });
            } else {
                this.activeStepNum++; //下一步
            }
            this.local_save();//添加表单缓存
        },

        //监听身份证输入框
        idcardInputWatchEvent() {
            this.formData.birthday = this.getBirthdayFromIdCard(this.formData.idcard);
            this.local_save();
        },

        //根据用户身份证输入，获取出生年月
        getBirthdayFromIdCard: function (idCard) {
            var birthday = "";
            if (idCard != null && idCard != "") {
                if (idCard.length == 15) {
                    birthday = "19" + idCard.substr(6, 6);
                } else if (idCard.length == 18) {
                    birthday = idCard.substr(6, 8);
                }

                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            }
            return birthday;
        },

        //获取工程极客入驻条例内容
        getArticleContent() {
            let _this = this;
            this.post("/article/index/detail", { id: 2 }, data => {
                if (data.code == 200) {
                    _this.articleContent = data.data.content;
                } else {
                    this.$message.error(data.msg);
                    return;
                }
            });
        },

        //缓存表单信息
        local_save() {
            this.$emit("onSave", { option: "change", data: this.formData });//提交到父组件，缓存表单值变更
        },

        //最终认证提交【保存】
        save() {
            let _this = this;
            let _formData = this.formData;

            //生日时间格式化
            if (_formData.birthday) {
                _formData.birthday = formatdata(
                    new Date(_formData.birthday).getTime(),
                    "yyyy-MM"
                );
            }

            //参加工作年月时间格式化
            if (_formData.experience_date) {
                _formData.experience_date = formatdata(
                    new Date(_formData.experience_date).getTime(),
                    "yyyy-MM"
                );
            }

            let has_achievement = 0;
            if (_formData.achievement instanceof Array) {
                _formData.achievement.forEach(function (item) {
                    if (item["title"]) {
                        has_achievement = 1;
                        return;
                    }
                });
            }
            if (has_achievement == 1) {
                _formData.achievement = _formData.achievement.map(c => c.id); //从业绩对象提出业绩id数组
            }

            //提交认证信息保存
            _this.loading = true;
            _this.post("/user/geek/attestation", _formData, data => {
                _this.loading = false;
                if (data.code == 200) {
                    _this.review_time = data.data.review_time; //审核时间
                    _this.activeStepNum = 4; //跳转到操作认证结果页面
                    _this.$emit("onSave", { option: "update", data: data.data });//提交到父组件更新用户信息
                    _this.$router.push("/user/attest/success");
                } else {
                    _this.$message.error(data.msg);
                    return;
                }
            });
        },
    },

    created() {
        this.getArticleContent(); //加载极客入驻条例数据
        if (this.attestation.name) {//如果父组件传过来有用户认证信息
            this.formData = this.attestation;//把父组件传进来的认证信息给表单
            this.formData.ability_tags = this.attestation.ability_tags ? this.attestation.ability_tags : [];
            if (this.attestation.experience == 1) {//参加工作年月
                this.experience = false;
                this.experience_date_disabled = false; //参加工作年月可用状态
                this.step.rules1.experience_date.required = true; //打开参加工作年月必填
            } else {
                this.experience = true;
                this.formData.experience_date = ""; //清空参加工作年月
                this.experience_date_disabled = true; //参加工作年月不可用状态
                this.step.rules1.experience_date.required = false; //关闭参加工作年月必填
            }
        }
        this.load_area(0, data => { this.areas = data; });//加载区域
        this.load_tags();//加载标签
        this.getUploadHeader(); //获取上传相关信息
    }
};
</script>
<style lang="less" scoped>
@import "../../../../../styles/ucattestation.less";
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.steps_box {
    .el-col {
        margin-bottom: 6px !important;
    }
}
.uploadImage,
.uploadFile{
    width: 0;
    height: 0;
    display: none;
}
</style>
